<!--
* @description:
* @fileName tagEdit.vue
* @author hvv
* @date 2022/01/18 09:36:48
!-->
<template>
  <el-dialog v-model="dialogFormVisible" :title="title" width="500px" @close="close">
    <el-form ref="formRef" label-width="80px" :model="form" :rules="rules">
      <el-form-item label="标题" prop="title">
        <el-input v-model="form.title" />
      </el-form-item>
      <el-form-item label="封面" prop="cover">
        <el-upload class="upload-demo" action="#" list-type="picture-card" :limit="1" :file-list="form.imgList" :http-request="upLogo" :on-error="handleError" :on-exceed="handleExceed1" :on-preview="handlePreview" :on-remove="handleRemove1" accept=".png,.jpeg,.jpg">
          <el-icon class="el-icon--upload"><upload-filled /></el-icon>
          <div class="el-upload__text">
            <em>点击上传封面</em>
          </div>
        </el-upload>
      </el-form-item>
      <el-form-item label="视频" prop="fileUrl">
        <wmt-image :src="form.fileUrl" @deleteImg="deleteBannar"></wmt-image>
        <el-upload accept=".mp4,.mov,.avi" action="#" class="upload-demo" :multiple="true" :http-request="upBannar" :show-file-list="false">
          <el-button>上传视频</el-button>
        </el-upload>
        <p>建议上传小于200MB的视频</p>

      </el-form-item>
      <el-form-item label="标签" prop="tagIds">
        <el-select v-model="form.tagIds" multiple placeholder="请选择标签">
          <template v-for="(item, index) in tagList" :key="index">
            <el-option :label="item.tagName" :value="item.id" />
          </template>
        </el-select>
      </el-form-item>
    </el-form>
    <template #footer>
      <el-button @click="close">取 消</el-button>
      <el-button type="primary" @click="save">确 定</el-button>
    </template>

    <el-dialog v-model="dialogVisible" append-to-body title="查看大图">
      <div>
        <el-image :src="dialogImageUrl" />
      </div>
    </el-dialog>

  </el-dialog>
</template>

<script>
import {
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  toRefs,
} from 'vue'
import { enterpriseAddMaterial, enterpriseEditMaterial } from '@/api/content'
import { upLoadImg, upLoadAttchments } from '@/api/active'
import wmtImage from '@/components/wmt-image'

export default defineComponent({
  name: 'tagEdit',
  emits: ['fetch-data'],
  components: { wmtImage },
  props: {
    enterpriseCode: {
      type: String,
      default: '',
    },
    tagList: {
      type: [Array, String],
      default: [],
    },
  },
  setup(props, { emit }) {
    const { proxy } = getCurrentInstance()

    const validateCover = (rule, value, callback) => {
      if (state.form.cover === '') {
        callback(new Error('请上传封面'))
      } else {
        callback()
      }
    }

    const validateVideo = (rule, value, callback) => {
      if (state.form.fileUrl === '') {
        callback(new Error('请上传视频'))
      } else {
        callback()
      }
    }

    const state = reactive({
      formRef: null,
      treeRef: null,
      checkMenu: [],
      dialogVisible: false,
      dialogImageUrl: '',
      form: {
        type: `Video`,
        attachmentId: '',
      },
      rules: {
        title: [{ required: true, trigger: 'blur', message: '请输入标题' }],
        cover: [
          { required: true, trigger: 'change', validator: validateCover },
        ],
        fileUrl: [
          { required: true, trigger: 'change', validator: validateVideo },
        ],
      },
      title: '',
      dialogFormVisible: false,
      list: [],
      tagOption: [],
    })

    const showEdit = async (row) => {
      if (!row) {
        state.title = '添加'
      } else {
        state.title = '编辑'
        row.imgList = [{ url: row.cover, name: row.cover }]
        row.type = 'Video'
        row.tagIds = row.materialTagVos.map((v) => v.id)
        state.form = Object.assign({}, row)
      }
      state.dialogFormVisible = true
    }
    const close = () => {
      state['formRef'].resetFields()
      state.form = {
        btnRolesCheckedList: [],
        type: `Video`,
        imgList: []
      }
      state.dialogFormVisible = false
    }

    const save = () => {
      state['formRef'].validate(async (valid) => {
        if (valid) {
          let msg = ''
          if (state.title == '编辑') {
            let data = await enterpriseEditMaterial(state.form)
            msg = data.msg
          } else {
            let data = await enterpriseAddMaterial(state.form)
            msg = data.msg
          }
          proxy.$baseMessage(`操作成功`, 'success', 'vab-hey-message-success')
          emit('fetch-data')
          close()
        }
      })
    }

    // 上传海报
    const upLogo = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadImg(upData)
      console.log(data)
      state.form.cover = data
    }

    const upBannar = async (e) => {
      let upData = new FormData()
      upData.append('file', e.file)
      const { data } = await upLoadAttchments(upData)
      console.log(data)
      state.form.fileUrl = data.fileUrl
      state.form.attachmentId = data.id
    }

    const deleteBannar = () => {
      state.form.fileUrl = ''
      state.form.attachmentId = ''
    }

    const handleError = (err, file) => {
      proxy.$baseMessage(
        `文件[${file.raw.name}]上传失败,文件大小为${_.round(
          file.raw.size / 1024,
          0
        )}KB`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleExceed1 = (files) => {
      proxy.$baseMessage(
        `当前限制选择1个文件，本次选择了
             ${files.length}
             个文件`,
        'error',
        'vab-hey-message-error'
      )
    }

    const handleRemove1 = (file, fileList) => {
      state.form.pageImage = ''
    }

    const handlePreview = (file) => {
      state.dialogImageUrl = file.url
      console.log(file.url)
      state.dialogVisible = true
    }

    return {
      ...toRefs(state),
      showEdit,
      close,
      save,
      upLogo,
      handleError,
      handleExceed1,
      handleRemove1,
      handlePreview,
      upBannar,
      deleteBannar,
    }
  },
})
</script>

<style lang="scss" scoped>
.vab-tree-border {
  height: 200px;
  padding: $base-padding;
  overflow-y: auto;
  border: 1px solid #dcdfe6;
  border-radius: $base-border-radius;
}
</style>
